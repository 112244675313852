<div class="header blue-border">
  <div class="title-wrapper">
    <h3 class="title">{{ 'GENERAL.HELP' | translate }}</h3>

    <button mat-icon-button id="helpDialogCloseButton" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-divider></mat-divider>

<div class="content">
  <div class="flexRow">
    <p class="icon-info large-icon"></p>
    <div [innerHTML]="dialogText" class="helpdialog-text"></div>
  </div>
</div>

<mat-divider></mat-divider>
<div class="button-row action-bar">
  <button mat-flat-button mat-dialog-close appFoucusElement id="helpDialogCancelButton" color="primary">
    {{ 'GENERAL.CLOSE' | translate }}
  </button>
</div>
