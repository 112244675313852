<div class="wrapper">
  <div class="flexColumn f-ai-c">
    <app-login-dialog class="box" [settings]="loginSettings"></app-login-dialog>
  </div>

  <div class="teaser">
    <h1 [innerHTML]="'LOGIN.WELCOME' | translate"></h1>
    <p [innerHTML]="'LOGIN.WELCOME_SUB' | translate"></p>
  </div>
</div>
