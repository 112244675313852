<div class="login content">
  <div>
    <div class="component box">
      <div class="panel">
        <div class="invoice-header" [innerHTML]="'LOGIN.E_INVOICING' | translate"></div>

        @if (!invoiceResponse) {
          <div class="spinner" style="height: 4em"></div>
        }

        @if (invoiceResponse) {
          <div class="invoice-content">
            @switch (digitalInvoiceState) {
              @case (2) {
                <div class="alert alert-success">
                  <div class="row align-center">
                    <span class="ui icon-success"></span>
                    <p class="message-title">{{ invoiceResponse.payload }}</p>
                  </div>
                </div>
              }
              @case (3) {
                <div class="alert alert-danger">
                  <div class="row align-center">
                    <span class="ui icon-alert"></span>
                    <p class="message-title">{{ invoiceResponse.payload }}</p>
                  </div>
                </div>
              }
              @case (4) {
                <div class="alert alert-danger">
                  <div class="row align-center">
                    <span class="ui icon-alert"></span>
                    <p class="message-title" [innerHTML]="'BACKEND_ERROR.TIMEOUT_TRY_AGAIN' | translate"></p>
                  </div>
                </div>
              }
            }
          </div>
        }

        <div class="separator"></div>

        <div class="invoice-actions">
          <span class="flex-spacer"></span>
          <button
            mat-flat-button
            class="forgot-password clickable"
            color="primary"
            id="appLoginGoToPortalClickable"
            (click)="redirectToPortal()"
            >
            {{ 'LOGIN.GO_TO_PORTAL' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="teaser">
    <div class="teaser-content">
      <h1 [innerHTML]="'LOGIN.WELCOME' | translate"></h1>
      <p [innerHTML]="'LOGIN.WELCOME_SUB' | translate"></p>
    </div>
  </div>
</div>
