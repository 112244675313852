<div class="header blue-border">
  <div class="title-wrapper">
    <h3 class="title">
      {{ 'TERMS_OF_SERVICE.TERMS_OF_SERVICE' | translate }}
    </h3>

    <button mat-icon-button id="termsOfServiceDialogCloseIconButton" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-divider></mat-divider>

<div class="content">
  {{ 'TERMS_OF_SERVICE.TERMS_OF_SERVICE_TEXT' | translate }}
</div>

<mat-divider></mat-divider>

<div class="button-row action-bar" style="align-items: center">
  <button mat-flat-button id="termsOfServiceDialogCloseButton" color="primary" mat-dialog-close>
    {{ 'GENERAL.CLOSE' | translate }}
  </button>
</div>
