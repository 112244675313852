<div class="wrapper">
  <div class="flexColumn f-ai-c">
    @if (loading) {
      <div class="box spinner-container loading">
        <span class="spinner"></span>
      </div>
    } @else {
      @if (invitingPartner && tab !== 'register') {
        <div class="inviter box">
          <div>
            <h4 [innerHtml]="'LOGIN.WELCOME_INVITE' | translate"></h4>
            <div class="flexRow f-ai-c">
              <div class="details">
                <div class="name" [title]="invitingPartner.fullName">{{ invitingPartner.fullName }}</div>
                <div class="flexRow vat detail f-ai-c">
                  <mat-icon [title]="'LOGIN.VAT' | translate">corporate_fare</mat-icon>
                  <div class="flex-spacer" [title]="invitingPartner.vatNr ?? ''">
                    {{ invitingPartner.vatNr ?? '-' }}
                  </div>
                </div>
                <div class="flexRow detail">
                  <mat-icon [title]="'LOGIN.EMAIL' | translate">email</mat-icon>
                  <div class="flex-spacer" [title]="invitingPartner.email ?? ''">
                    {{ invitingPartner.email ?? '-' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      @if (tab === 'login') {
        <app-login-dialog [settings]="loginSettings" [error]="error"></app-login-dialog>
      } @else {
        <div class="box register flexColumn">
          <div class="flexRow f-ai-c">
            <h4>
              {{ 'LOGIN.CREATE_ACCOUNT' | translate }}
            </h4>
            <span class="flex-spacer"></span>
            <button mat-icon-button id="appLoginHelpDialogButton" (click)="openHelpDialog()">
              <mat-icon>help</mat-icon>
            </button>
          </div>

          <mat-stepper linear="true" #stepper (selectionChange)="stepChange($event)" class="step-header-min-width registration">
            <mat-step [completed]="companyForm.valid" [editable]="!waitingForRegisterResponse">
              <ng-template matStepLabel>
                <div [ngClass]="{ 'selected-step': currentStep === 0 }" class="text-ellipsis" [title]="'LOGIN.COMPANY' | translate">
                  {{ 'LOGIN.COMPANY' | translate }}
                </div>
              </ng-template>

              <form [formGroup]="companyForm" class="dark">
                <mat-form-field class="no-hint-padding margin-bottom-05">
                  <mat-label>
                    {{ 'LOGIN.COMPANY_NAME' | translate }}
                  </mat-label>
                  <input
                    matInput
                    readonly
                    formControlName="fullName"
                    required
                    autocomplete="turnoff"
                    [errorStateMatcher]="errorStateMatcher"
                  />

                  <ng-container matSuffix>
                    @if (
                      (companyForm.controls['fullName'].touched || companyForm.controls['fullName'].dirty) &&
                      !companyForm.controls['fullName'].valid &&
                      companyForm.controls['fullName'].errors?.required
                    ) {
                      <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
                    }
                  </ng-container>
                </mat-form-field>

                <div class="flexRow margin-bottom-05">
                  <mat-form-field class="no-hint-padding margin-right-025 flex-1-0-0">
                    <mat-label>
                      {{ 'LOGIN.VAT_NUMBER' | translate }}
                    </mat-label>

                    <input matInput formControlName="vatNr" required autocomplete="turnoff" [errorStateMatcher]="errorStateMatcher" />

                    <ng-container matSuffix>
                      @if (
                        (companyForm.controls['vatNr'].touched || companyForm.controls['vatNr'].dirty) &&
                        !companyForm.controls['vatNr'].valid &&
                        companyForm.controls['vatNr'].errors
                      ) {
                        @if (companyForm.controls['vatNr'].errors.required) {
                          <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
                        } @else if (companyForm.controls['vatNr'].errors.pattern) {
                          <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_INVALID' | translate"> error_outline </mat-icon>
                        } @else if (companyForm.controls['vatNr'].errors.vatExists) {
                          <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_ALREADY_USED_VALUE' | translate">
                            error_outline
                          </mat-icon>
                        }
                      }
                    </ng-container>
                  </mat-form-field>

                  <mat-form-field class="no-hint-padding margin-left-025 flex-1-0-0">
                    <mat-label>
                      {{ 'LOGIN.COUNTRY' | translate }}
                    </mat-label>
                    <input
                      type="text"
                      matInput
                      required
                      formControlName="country"
                      [errorStateMatcher]="errorStateMatcher"
                      autocomplete="turnoff"
                      [matAutocomplete]="auto"
                      (blur)="checkValidCountry()"
                    />
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="showcountryFull">
                      @for (country of filteredOptions | async; track country) {
                        <mat-option [value]="country">
                          {{ country.countryFull }}
                        </mat-option>
                      }
                      @if (nocountrys) {
                        <mat-option disabled> {{ 'GENERAL.NO_DATA' | translate }}</mat-option>
                      }
                    </mat-autocomplete>

                    <ng-container matSuffix>
                      @if (
                        (companyForm.controls['country'].touched || companyForm.controls['country'].dirty) &&
                        !companyForm.controls['country'].value &&
                        companyForm.controls['country'].errors?.required
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
                      }
                      @if (
                        (companyForm.controls['country'].touched || companyForm.controls['country'].dirty) &&
                        companyForm.controls['country'].errors?.countryError
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'LOGIN.INVALID_COUNTRY' | translate"> error_outline </mat-icon>
                      }
                    </ng-container>
                  </mat-form-field>
                </div>

                <div class="flexRow margin-bottom-05">
                  <mat-form-field class="no-hint-padding margin-right-025 flex-2-0-0">
                    <mat-label>
                      {{ 'LOGIN.STREET' | translate }}
                    </mat-label>

                    <input matInput formControlName="street" required [errorStateMatcher]="errorStateMatcher" />

                    <ng-container matSuffix>
                      @if (
                        (companyForm.controls['street'].touched || companyForm.controls['street'].dirty) &&
                        !companyForm.controls['street'].valid &&
                        companyForm.controls['street'].errors?.required
                      ) {
                        <mat-icon class="error-icon" autocomplete="turnoff" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate">
                          error_outline
                        </mat-icon>
                      }
                    </ng-container>
                  </mat-form-field>

                  <mat-form-field class="no-hint-padding margin-left-025 flex-1-0-0 mat-form-field-overwrite-infix-width">
                    <mat-label>
                      {{ 'LOGIN.HOUSE_NUMBER' | translate }}
                    </mat-label>

                    <input matInput formControlName="houseNr" autocomplete="turnoff" required [errorStateMatcher]="errorStateMatcher" />

                    <ng-container matSuffix>
                      @if (
                        (companyForm.controls['houseNr'].touched || companyForm.controls['houseNr'].dirty) &&
                        !companyForm.controls['houseNr'].valid &&
                        companyForm.controls['houseNr'].errors?.required
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
                      }
                    </ng-container>
                  </mat-form-field>
                </div>

                <div class="flexRow margin-bottom-05">
                  <mat-form-field class="no-hint-padding margin-right-025 flex-1-0-0 mat-form-field-overwrite-infix-width">
                    <mat-label>
                      {{ 'LOGIN.ZIP_CODE' | translate }}
                    </mat-label>

                    <input matInput formControlName="zipCode" autocomplete="turnoff" required [errorStateMatcher]="errorStateMatcher" />

                    <ng-container matSuffix>
                      @if (
                        (companyForm.controls['zipCode'].touched || companyForm.controls['zipCode'].dirty) &&
                        !companyForm.controls['zipCode'].valid &&
                        companyForm.controls['zipCode'].errors?.required
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
                      }
                    </ng-container>
                  </mat-form-field>
                  <mat-form-field class="no-hint-padding margin-left-025 flex-2-0-0">
                    <mat-label>
                      {{ 'LOGIN.CITY' | translate }}
                    </mat-label>

                    <input matInput formControlName="city" autocomplete="turnoff" required [errorStateMatcher]="errorStateMatcher" />

                    <ng-container matSuffix>
                      @if (
                        (companyForm.controls['city'].touched || companyForm.controls['city'].dirty) &&
                        !companyForm.controls['city'].valid &&
                        companyForm.controls['city'].errors?.required
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
                      }
                    </ng-container>
                  </mat-form-field>
                </div>

                <h5 class="margin-bottom-05 margin-top-1">{{ 'LOGIN.CONTACT_DATA' | translate }}</h5>

                <div class="flexRow margin-bottom-05">
                  <mat-form-field class="no-hint-padding flex-1-0-0 margin-right-025">
                    <mat-label>
                      {{ 'LOGIN.COUNTRY_CALLING_CODE' | translate }}
                    </mat-label>
                    <input
                      matInput
                      appTrim="blur"
                      autocomplete="turnoff"
                      formControlName="phonePrefix"
                      required
                      [errorStateMatcher]="errorStateMatcher"
                    />

                    <mat-icon matPrefix>add</mat-icon>

                    <ng-container matSuffix>
                      @if (
                        (companyForm.controls['phonePrefix'].touched || companyForm.controls['phonePrefix'].dirty) &&
                        !companyForm.controls['phonePrefix'].value &&
                        companyForm.controls['phonePrefix'].errors?.required
                      ) {
                        <mat-icon class="error-icon" autocomplete="turnoff" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate">
                          error_outline
                        </mat-icon>
                      }
                      @if (
                        (companyForm.controls['phonePrefix'].touched || companyForm.controls['phonePrefix'].dirty) &&
                        companyForm.controls['phonePrefix'].value &&
                        companyForm.controls['phonePrefix'].errors
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'LOGIN.INVALID_INPUT' | translate"> error_outline </mat-icon>
                      }
                    </ng-container>
                  </mat-form-field>
                  <mat-form-field class="no-hint-padding margin-left-025 flex-1-0-0">
                    <mat-label>
                      {{ 'LOGIN.PHONE_NUMBER' | translate }}
                    </mat-label>

                    <input
                      matInput
                      appTrim="blur"
                      autocomplete="turnoff"
                      formControlName="phone"
                      required
                      [errorStateMatcher]="errorStateMatcher"
                    />

                    <ng-container matSuffix>
                      @if (
                        (companyForm.controls['phone'].touched || companyForm.controls['phone'].dirty) &&
                        !companyForm.controls['phone'].value &&
                        companyForm.controls['phone'].errors?.required
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
                      }
                      @if (
                        (companyForm.controls['phone'].touched || companyForm.controls['phone'].dirty) &&
                        companyForm.controls['phone'].value &&
                        companyForm.controls['phone'].errors
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'LOGIN.INVALID_INPUT' | translate"> error_outline </mat-icon>
                      }
                    </ng-container>
                  </mat-form-field>
                </div>

                <div class="flexRow margin-bottom-05">
                  <mat-form-field class="no-hint-padding flex-1-0-0">
                    <mat-label>
                      {{ 'GENERAL.MAIL' | translate }}
                    </mat-label>

                    <input
                      matInput
                      appTrim="blur"
                      autocomplete="turnoff"
                      formControlName="email"
                      required
                      [errorStateMatcher]="errorStateMatcher"
                    />

                    <ng-container matSuffix>
                      @if (
                        (companyForm.controls['email'].touched || companyForm.controls['email'].dirty) &&
                        !companyForm.controls['email'].value &&
                        companyForm.controls['email'].errors?.required
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
                      }
                      @if (
                        (companyForm.controls['email'].touched || companyForm.controls['email'].dirty) &&
                        companyForm.controls['email'].value &&
                        companyForm.controls['email'].errors
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'LOGIN.INVALID_INPUT' | translate"> error_outline </mat-icon>
                      }
                    </ng-container>
                  </mat-form-field>
                </div>
              </form>

              @if (companyForm?.controls?.['vatNr']?.errors?.vatExists) {
                <div class="flexRow vat-exists">
                  <span class="flex-spacer">{{ 'LOGIN.NO_VAT_EXPLANATION' | translate }}</span>
                  <button mat-stroked-button (click)="setTab('login')">
                    {{ 'LOGIN.GO_TO_LOGIN' | translate }}
                  </button>
                </div>
              }

              <div class="flexRow">
                <span class="flex-spacer"></span>
                <button mat-stroked-button class="margin-right-05-important dark" (click)="setTab('login')">
                  {{ 'LOGIN.CANCEL' | translate }}
                </button>
                <button mat-flat-button color="primary" [disabled]="!companyForm.valid" matStepperNext>
                  {{ 'LOGIN.CONTINUE' | translate }}
                </button>
              </div>
            </mat-step>

            <mat-step [label]="'LOGIN.ADMIN_USER' | translate" [completed]="userForm.valid" [editable]="!waitingForRegisterResponse">
              <ng-template matStepLabel>
                <div [ngClass]="{ 'selected-step': currentStep === 1 }" class="text-ellipsis" [title]="'LOGIN.ADMIN_USER' | translate">
                  {{ 'LOGIN.ADMIN_USER' | translate }}
                </div>
              </ng-template>

              <form [formGroup]="userForm" class="dark">
                <mat-form-field class="no-hint-padding">
                  <mat-label>
                    {{ 'USER.EMAIL_ADDRESS' | translate }}
                  </mat-label>

                  <input
                    matInput
                    formControlName="mail"
                    autocomplete="turnoff"
                    required
                    [errorStateMatcher]="errorStateMatcher"
                    type="email"
                  />

                  <ng-container matSuffix>
                    @if (
                      (userForm.controls['mail'].touched || userForm.controls['mail'].dirty) &&
                      !userForm.controls['mail'].valid &&
                      userForm.controls['mail'].errors?.required &&
                      !userForm.controls['mail'].errors?.pattern
                    ) {
                      <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
                    }
                    @if (
                      (userForm.controls['mail'].touched || userForm.controls['mail'].dirty) &&
                      !userForm.controls['mail'].valid &&
                      userForm.controls['mail'].errors?.pattern &&
                      !userForm.controls['mail'].errors?.required
                    ) {
                      <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_EMAIL' | translate"> error_outline </mat-icon>
                    }
                  </ng-container>
                </mat-form-field>

                <div class="flexRow">
                  <mat-form-field class="no-hint-padding margin-right-025 flex-1-0-0">
                    <mat-label>
                      {{ 'USER.FIRST_NAME' | translate }}
                    </mat-label>

                    <input
                      readonly
                      matInput
                      formControlName="firstName"
                      autocomplete="turnoff"
                      required
                      [errorStateMatcher]="errorStateMatcher"
                    />

                    <ng-container matSuffix>
                      @if (
                        (userForm.controls['firstName'].touched || userForm.controls['firstName'].dirty) &&
                        !userForm.controls['firstName'].valid &&
                        userForm.controls['firstName'].errors?.required
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
                      }
                      @if (
                        (userForm.controls['firstName'].touched || userForm.controls['firstName'].dirty) &&
                        !userForm.controls['firstName'].errors?.required &&
                        (userForm.controls['firstName'].errors?.minlength || userForm.controls['firstName'].errors?.maxlength)
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_STRING_LENGTH' | translate: { min: '2', max: '100' }">
                          error_outline
                        </mat-icon>
                      }
                    </ng-container>
                  </mat-form-field>
                  <mat-form-field class="no-hint-padding margin-left-025 flex-1-0-0">
                    <mat-label>
                      {{ 'USER.LAST_NAME' | translate }}
                    </mat-label>

                    <input
                      matInput
                      readonly
                      formControlName="lastName"
                      autocomplete="turnoff"
                      required
                      [errorStateMatcher]="errorStateMatcher"
                    />

                    <ng-container matSuffix>
                      @if (
                        (userForm.controls['lastName'].touched || userForm.controls['lastName'].dirty) &&
                        !userForm.controls['lastName'].valid &&
                        userForm.controls['lastName'].errors?.required
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
                      }
                      @if (
                        (userForm.controls['lastName'].touched || userForm.controls['lastName'].dirty) &&
                        !userForm.controls['lastName'].errors?.required &&
                        (userForm.controls['lastName'].errors?.minlength || userForm.controls['lastName'].errors?.maxlength)
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_STRING_LENGTH' | translate: { min: '2', max: '100' }">
                          error_outline
                        </mat-icon>
                      }
                    </ng-container>
                  </mat-form-field>
                </div>

                <h5 class="margin-top-1">
                  {{ 'USER.LOGIN_DATA' | translate }}
                </h5>

                <div class="flexRow">
                  <mat-form-field class="no-hint-padding margin-right-025 flex-1-0-0">
                    <mat-label>
                      {{ 'USER.USERNAME' | translate }}
                    </mat-label>

                    <input matInput formControlName="login" autocomplete="turnoff" required [errorStateMatcher]="errorStateMatcher" />

                    <ng-container matSuffix>
                      @if (
                        (userForm.controls['login'].touched || userForm.controls['login'].dirty) &&
                        !userForm.controls['login'].valid &&
                        userForm.controls['login'].errors?.required
                      ) {
                        <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
                      } @else {
                        <mat-icon
                          [ngClass]="{
                            'error-icon':
                              (userForm.controls['login'].touched || userForm.controls['login'].dirty) && !userForm.controls['login'].valid,
                          }"
                          [matTooltip]="'USER.USERNAME_RULE' | translate"
                        >
                          {{
                            (userForm.controls['login'].touched || userForm.controls['login'].dirty) && !userForm.controls['login'].valid
                              ? 'error_outline'
                              : 'help_outline'
                          }}
                        </mat-icon>
                      }
                    </ng-container>
                  </mat-form-field>

                  <mat-form-field class="no-hint-padding margin-left-025 flex-1-0-0">
                    <mat-label>
                      {{ 'USER.ORGA_ID' | translate }}
                    </mat-label>

                    <input
                      matInput
                      formControlName="organisation"
                      autocomplete="turnoff"
                      required
                      [errorStateMatcher]="errorStateMatcher"
                    />

                    <ng-container matSuffix>
                      @if (
                        (userForm.controls['organisation'].touched || userForm.controls['organisation'].dirty) &&
                        userForm.controls['organisation'].errors?.required
                      ) {
                        <mat-icon [matTooltip]="'ERROR.ERROR_REQUIRED' | translate" class="error-icon"> error_outline </mat-icon>
                      } @else {
                        @if (
                          (userForm.controls['organisation'].touched || userForm.controls['organisation'].dirty) &&
                          userForm.controls['organisation'].errors
                        ) {
                          <mat-icon
                            [matTooltip]="
                              (userForm.controls['organisation'].errors?.alreadyExists
                                ? 'BACKEND_ERROR.ORGANISATION_LOGIN_ALREADY_EXISTS'
                                : 'USER.ORGAID_RULE'
                              ) | translate: { min: orgaIdMinLength, max: orgaIdMaxLength }
                            "
                            class="error-icon"
                          >
                            error_outline
                          </mat-icon>
                        } @else {
                          <mat-icon [matTooltip]="'USER.ORGAID_RULE' | translate: { min: orgaIdMinLength, max: orgaIdMaxLength }">
                            help_outline
                          </mat-icon>
                        }
                      }
                    </ng-container>
                  </mat-form-field>
                </div>

                <mat-form-field class="no-hint-padding">
                  <mat-label>
                    {{ 'USER.NEW_PASSWORD' | translate }}
                  </mat-label>

                  <input
                    matInput
                    formControlName="password"
                    autocomplete="turnoff"
                    required
                    [errorStateMatcher]="errorStateMatcher"
                    type="password"
                  />

                  <mat-icon
                    matSuffix
                    [ngClass]="{
                      'error-icon':
                        (userForm.controls['password'].touched || userForm.controls['password'].dirty) &&
                        !userForm.controls['password'].valid,
                    }"
                    [matTooltip]="
                      userForm.controls['password'].errors?.minlength || userForm.controls['password'].errors?.maxlength
                        ? ('ERROR.ERROR_STRING_LENGTH' | translate)
                        : passwordRestrictionsTooltip
                    "
                    matTooltipClass="password-tooltip"
                    #newPasswordTooltip="matTooltip"
                  >
                    {{
                      (userForm.controls['password'].touched || userForm.controls['password'].dirty) && !userForm.controls['password'].valid
                        ? 'error_outline'
                        : 'help_outline'
                    }}
                  </mat-icon>
                </mat-form-field>

                <mat-form-field class="no-hint-padding">
                  <mat-label>
                    {{ 'USER.NEW_PASSWORD_REPEAT' | translate }}
                  </mat-label>

                  <input
                    matInput
                    formControlName="confirmPassword"
                    autocomplete="turnoff"
                    required
                    [errorStateMatcher]="errorStateMatcher"
                    type="password"
                  />

                  <ng-container matSuffix>
                    @if (
                      (userForm.controls['confirmPassword'].touched || userForm.controls['confirmPassword'].dirty) &&
                      !userForm.controls['confirmPassword'].valid &&
                      userForm.controls['confirmPassword'].errors?.required
                    ) {
                      <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_REQUIRED' | translate"> error_outline </mat-icon>
                    }
                    @if (
                      (userForm.controls['confirmPassword'].touched || userForm.controls['confirmPassword'].dirty) &&
                      !userForm.controls['confirmPassword'].valid &&
                      !userForm.controls['confirmPassword'].errors?.required
                    ) {
                      <mat-icon class="error-icon" [matTooltip]="'ERROR.ERROR_PASSWORD_REPEAT' | translate"> error_outline </mat-icon>
                    }
                  </ng-container>
                </mat-form-field>
              </form>

              <div class="flexRow">
                <span class="flex-spacer"></span>
                <button mat-stroked-button class="margin-right-05-important dark" matStepperPrevious>
                  {{ 'LOGIN.PREVIOUS' | translate }}
                </button>
                <button mat-flat-button color="primary" [disabled]="!userForm.valid" matStepperNext>
                  {{ 'LOGIN.CONTINUE' | translate }}
                </button>
              </div>
            </mat-step>

            <mat-step
              [label]="'LOGIN.SYSTEM' | translate"
              [completed]="userForm.valid && userSettingsForm.valid && userSettingsForm.touched"
              [editable]="!waitingForRegisterResponse"
            >
              <ng-template matStepLabel>
                <div [ngClass]="{ 'selected-step': currentStep === 2 }" class="text-ellipsis" [title]="'LOGIN.SYSTEM' | translate">
                  {{ 'LOGIN.SYSTEM' | translate }}
                </div>
              </ng-template>

              <form [formGroup]="userSettingsForm" class="dark">
                <div class="flexRow margin-bottom-1">
                  <mat-form-field class="no-hint-padding margin-right-025 flex-1-0-0">
                    <mat-label>
                      {{ 'LANG.LANGUAGE' | translate }}
                    </mat-label>
                    <mat-select formControlName="language" required [errorStateMatcher]="errorStateMatcher">
                      @for (lang of languages; track lang) {
                        <mat-option [value]="lang">
                          {{ 'LANG.LANGUAGE-' + lang | translate }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="no-hint-padding margin-left-025 flex-1-0-0">
                    <mat-label>
                      {{ 'USER.DATE_FORMAT' | translate }}
                    </mat-label>
                    <mat-select formControlName="dateFormat" required [errorStateMatcher]="errorStateMatcher">
                      @for (format of dateFormats; track format) {
                        <mat-option [value]="format">
                          {{ 'USER.DATE_FORMAT-' + format | translate }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="flexRow margin-bottom-1">
                  <mat-form-field class="no-hint-padding margin-right-025 flex-1-0-0">
                    <mat-label>
                      {{ 'USER.NUMBER_FORMAT' | translate }}
                    </mat-label>
                    <mat-select formControlName="numberFormat" required [errorStateMatcher]="errorStateMatcher">
                      @for (unit of numberFormats; track unit) {
                        <mat-option [value]="unit.value">
                          {{ 'USER.NUMBER_FORMAT-' + unit.key | translate }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="no-hint-padding margin-left-025 flex-1-0-0">
                    <mat-label>
                      {{ 'USER.TIMEZONE' | translate }}
                    </mat-label>
                    <mat-select formControlName="timeZoneUnit" required [errorStateMatcher]="errorStateMatcher">
                      @for (zone of timeZones; track zone) {
                        <mat-option [value]="zone.key">
                          {{ 'TIME_ZONE_INTL.' + zone.name | translate }} ({{ 'TIME_ZONE_INTL.UTC' | translate }}
                          {{ zone.formattedOffset }})
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="flexRow margin-bottom-1">
                  <mat-form-field class="no-hint-padding margin-right-025 flex-1-0-0">
                    <mat-label>
                      {{ 'USER.DISTANCE_UNIT' | translate }}
                    </mat-label>
                    <mat-select formControlName="distanceSystem" required [errorStateMatcher]="errorStateMatcher">
                      @for (system of distanceSystems; track system) {
                        <mat-option [value]="system.value">
                          {{ 'USER.DISTANCE_UNIT-' + system.key | translate }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="no-hint-padding margin-right-025 flex-1-0-0">
                    <mat-label>
                      {{ 'USER.WEIGHT_SYSTEM' | translate }}
                    </mat-label>
                    <mat-select formControlName="weightSystem" required [errorStateMatcher]="errorStateMatcher">
                      @for (system of weightSystems; track system) {
                        <mat-option [value]="system.value">
                          {{ 'USER.WEIGHT_SYSTEM-' + system.key | translate }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="flexRow margin-bottom-1">
                  <mat-form-field class="no-hint-padding margin-right-025 flex-1-0-0">
                    <mat-label>
                      {{ 'USER.VOLUME_UNIT' | translate }}
                    </mat-label>
                    <mat-select formControlName="volumeSystem" required [errorStateMatcher]="errorStateMatcher">
                      @for (system of volumeSystems; track system) {
                        <mat-option [value]="system.value">
                          {{ 'USER.VOLUME_UNIT-' + system.key | translate }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="no-hint-padding margin-right-025 flex-1-0-0">
                    <mat-label>
                      {{ 'USER.TEMPERATURE_UNIT' | translate }}
                    </mat-label>
                    <mat-select formControlName="temperatureSystem" required [errorStateMatcher]="errorStateMatcher">
                      @for (system of temperatureSystems; track system) {
                        <mat-option [value]="system.value">
                          {{ 'USER.TEMPERATURE_UNIT-' + system.key | translate }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="flexRow margin-bottom-1">
                  <mat-form-field class="no-hint-padding margin-right-025 flex-1-0-0">
                    <mat-label>
                      {{ 'USER.PRESSURE_UNIT' | translate }}
                    </mat-label>
                    <mat-select formControlName="pressureSystem" required [errorStateMatcher]="errorStateMatcher">
                      @for (system of pressureSystems; track system) {
                        <mat-option [value]="system.value">
                          {{ 'USER.PRESSURE_UNIT-' + system.key | translate }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>

                  <div class="flex-spacer"></div>
                </div>
              </form>

              <div class="flexRow">
                <span class="flex-spacer"></span>
                <button mat-stroked-button class="margin-right-05-important dark" matStepperPrevious>
                  {{ 'LOGIN.PREVIOUS' | translate }}
                </button>
                <button mat-flat-button color="primary" matStepperNext [disabled]="!userSettingsForm.valid">
                  {{ 'LOGIN.CONTINUE' | translate }}
                </button>
              </div>
            </mat-step>

            <mat-step [label]="'LOGIN.CONFIRMATION' | translate" [completed]="confirmationForm.valid">
              <ng-template matStepLabel>
                <div [ngClass]="{ 'selected-step': currentStep === 3 }" class="text-ellipsis" [title]="'LOGIN.CONFIRMATION' | translate">
                  {{ 'LOGIN.CONFIRMATION' | translate }}
                </div>
              </ng-template>

              <div class="flexRow margin-bottom-1 overflow-hidden">
                <div class="flexColumn flex-1-0-0 margin-right-05 overflow-hidden">
                  <h5>
                    {{ 'LOGIN.COMPANY' | translate }}
                  </h5>
                  <p class="text-ellipsis">
                    {{ companyForm.value?.fullName }}
                  </p>
                  <p class="text-ellipsis">
                    {{ companyForm.value?.street }} {{ companyForm.value?.houseNr }}, {{ companyForm.value?.zipCode }}
                    {{ companyForm.value?.city }}
                  </p>
                  <p class="text-ellipsis">
                    {{ companyForm.value?.country?.countryFull ?? 'LOGIN.INVALID_COUNTRY' | translate }}
                  </p>
                  <p class="text-ellipsis" [title]="('LOGIN.VAT_NUMBER' | translate) + ' ' + companyForm.value?.vatNr">
                    {{ 'LOGIN.VAT_NUMBER' | translate }}:<br />
                    {{ companyForm.value?.vatNr }}
                  </p>
                </div>
                <div class="flexColumn flex-1-0-0 margin-left-05 overflow-hidden">
                  <div class="flexColumn margin-bottom-1 overflow-hidden">
                    <h5>
                      {{ 'LOGIN.ADMIN_USER' | translate }}
                    </h5>
                    <p class="text-ellipsis">
                      {{ userForm.value?.mail }}
                    </p>
                    <p class="text-ellipsis">{{ userForm.value?.firstName }} {{ userForm.value?.lastName }}</p>
                  </div>
                  <div class="flexColumn">
                    <h5>
                      {{ 'USER.LOGIN_DATA' | translate }}
                    </h5>
                    <p class="text-ellipsis">{{ 'USER.USERNAME' | translate }}: {{ userForm.value?.login }}</p>
                    <p>{{ 'USER.ORGA_ID' | translate }}: {{ userForm.value?.organisation }}</p>
                  </div>
                </div>
              </div>

              <form [formGroup]="confirmationForm" class="dark">
                <mat-checkbox color="primary" formControlName="termsOfService" [disabled]="waitingForRegisterResponse">
                  <span>
                    {{ 'TERMS_OF_SERVICE.ACCEPT' | translate }}
                    <span class="link" (click)="$event.preventDefault(); $event.stopPropagation(); openTermsOfServiceDialog()">
                      {{ 'TERMS_OF_SERVICE.TERMS_OF_SERVICE' | translate }}
                    </span>
                  </span>
                </mat-checkbox>

                <mat-checkbox color="primary" formControlName="dataProcessingAgreement" [disabled]="waitingForRegisterResponse">
                  <span>
                    {{ 'TERMS_OF_SERVICE.ACCEPT' | translate }}
                    <span class="link" (click)="$event.preventDefault(); $event.stopPropagation(); openDpaDialog()">
                      {{ 'DPA.DPA' | translate }}
                    </span>
                    {{ 'TERMS_OF_SERVICE.ACCEPT_END' | translate }}
                  </span>
                </mat-checkbox>
              </form>

              <div class="flexRow">
                <span class="flex-spacer"></span>
                <button
                  mat-stroked-button
                  class="margin-right-05-important dark"
                  matStepperPrevious
                  [disabled]="waitingForRegisterResponse"
                >
                  {{ 'LOGIN.PREVIOUS' | translate }}
                </button>
                <button
                  mat-flat-button
                  color="primary"
                  [disabled]="!confirmationForm.valid || waitingForRegisterResponse"
                  [appButtonLoading]="waitingForRegisterResponse"
                  (click)="performRegistration()"
                >
                  {{ 'LOGIN.REGISTER_AND_ACCEPT' | translate }}
                </button>
              </div>
            </mat-step>
          </mat-stepper>
        </div>
      }
    }
  </div>

  @if (tab === 'login') {
    <div class="teaser">
      <h1 [innerHTML]="'LOGIN.WELCOME' | translate"></h1>
      <p [innerHTML]="'LOGIN.WELCOME_SUB' | translate"></p>
    </div>
  }
</div>
