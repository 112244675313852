export enum AuthenticationResult {
  None = 0,
  Redirect = 1,
  Failed = 2,
  Error = 3,
  NotYetAllowed = 4,
  NotAnymoreAllowed = 5,
  OrganisationNotWhitelisted = 6,
  InviteNotApplicable = 7,
  InvitePermissionMissing = 8,
  RegistrationFailed = 9,
  OrganisationBlockedOrInactive = 10,
}
