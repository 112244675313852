import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IOrganisationMasterData } from 'src/app/models/organisation-master-data';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  readonly #http = inject(HttpClient);

  getOrganisationMasterData(uuid: string, token: string): Observable<HttpResponse<IOrganisationMasterData>> {
    return this.#http
      .get<IOrganisationMasterData>('api/partner/masterdata/' + uuid + '/' + encodeURIComponent(token), { observe: 'response' })
      .pipe(catchError((response) => of(response)));
  }

  getCountries(): Observable<[{ countryShort: string; countryFull: string[] }]> {
    return this.#http.get<any>('api/countries').pipe(
      map((r) => {
        const countries = [];

        Object.entries(r.countries.name).forEach(([key, value]) => {
          countries.push({ countryShort: key, countryFull: [value as string] });
        });

        Object.entries(r.countries.official).forEach(([key, value]) => {
          const existingCountry = countries.find((country) => country.countryShort === key);
          if (existingCountry) {
            existingCountry.countryFull.push(value as string);
          } else {
            countries.push({ countryShort: key, countryFull: [value as string] });
          }
        });

        Object.entries(r.countries.search).forEach(([key, value]) => {
          const existingCountry = countries.find((country) => country.countryShort === key);
          if (existingCountry) {
            existingCountry.countryFull.push(value as string);
          } else {
            countries.push({ countryShort: key, countryFull: [value as string] });
          }
        });
        return countries;
      }),
      catchError((response) => of(response)),
    );
  }
}
