import { getStringKeyAndNumberValueArrayFromEnum } from './get-string-and-number-value-array-from-enum';
import { TIMEZONE_OFFSET, TimeZoneUnit } from './time-zone-unit';

export const timeZones = getStringKeyAndNumberValueArrayFromEnum(TimeZoneUnit);

export function getPortalTimeZoneFromBrowserTimeZoneOffset(fullTimeZoneUnits: FullTimeZoneUnit[]): FullTimeZoneUnit | undefined {
  // getTimezoneOffset gets offset in minutes from utc so we divide by 60 for hours
  // and multiply by -1 to get our familiar timeZoneOffsets
  // the date is chosen to be in the standard or winter time
  const timeZoneOffset = (new Date('November 1, 2000 00:00:00').getTimezoneOffset() / 60) * -1;
  return fullTimeZoneUnits[TIMEZONE_OFFSET[timeZoneOffset]];
}

export const utilityLogoTimeZones = ['AST', 'EST', 'CST', 'MST', 'PST'];

export const utilityLogoLanguageSuffixes = ['-CA', '-MX', '-US'];

export function shouldDisplayUtilityLogo(): boolean {
  const portalTimeZone = getPortalTimeZoneFromBrowserTimeZoneOffset(timeZones);
  return utilityLogoTimeZones.includes(portalTimeZone?.key)
    && utilityLogoLanguageSuffixes.some((suffix) => navigator.language.includes(suffix));
}

export type FullTimeZoneUnit = { key: string; value: number };
