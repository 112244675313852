@if (!logoutFailed) {
  <div>{{ 'LOGIN.LOGOUT_IN_PROGRESS' | translate }}</div>
} @else {
  <div>
    {{ 'LOGIN.LOGOUT_FAILED' | translate }}
    <br />
    <br />
    <button mat-flat-button color="primary" (click)="logoutRetry = true; doLogout(logoutToken)" [appButtonLoading]="logoutRetry">
      {{ 'LOGIN.RETRY' | translate }}
    </button>
  </div>
}
