import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IDigitalInvoiceResponse } from '../models/digital-invoice-response';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  readonly #http = inject(HttpClient);

  public getInvoiceResponse(hash: string, language: string): Observable<IDigitalInvoiceResponse | null> {
    return this.#http
      .post('api/v1/invoice/optIn', null, {
        observe: 'response',
        params: {
          hash,
          language,
        },
      })
      .pipe(
        map((res) => res.body as IDigitalInvoiceResponse),
        catchError(() => of(null)),
      );
  }
}
