import { inject, Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { IDpaLatestDto } from '../models/dpa-latest-dto';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DataProcessingAgreementService {
  readonly #http = inject(HttpClient);
  getDpaConfirmationRequested(): Observable<IDpaLatestDto | undefined> {
    return this.#http.get<IDpaLatestDto>('api/account/GetConfirmationRequested').pipe(catchError(() => of(undefined)));
  }

  getDpaTextByLanguage(dpaUuid: string, language: string): Observable<string | undefined> {
    return this.#http
      .get<string>(`api/account/GetConfirmationRequestedPreview/${dpaUuid}/${language}`)
      .pipe(catchError(() => of(undefined)));
  }

  /**
   * Fetches the text of the data_processing_agreement_localized row that is tied to the data_processing_agreement row where the column superseded_by has the value null.
   * @param language determines which exact row to read the value of the text column for
   * @returns
   */
  getDpaTextAsPdf(language: string): Observable<HttpResponse<any> | undefined> {
    return this.#http
      .get(`api/account/getconfirmationrequesteddownload/${language}`, {
        responseType: 'blob',

        observe: 'response',
      })
      .pipe(catchError(() => of(undefined)));
  }
}
