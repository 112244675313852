export class Helper {
  static getUrlParams(search: string): Record<string, string> {
    if (!search) {
      return {};
    }
    const hashes = search.slice(search.indexOf('?') + 1).split('&');
    const params: { [key: string]: string } = {};

    hashes.map((hash) => {
      const [key, val] = hash.split('=');
      params[key] = decodeURIComponent(val);
    });
    return params;
  }
}
