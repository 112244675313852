import { Component, inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-imprint-dialog',
  templateUrl: './imprint-dialog.component.html',
  styleUrls: ['./imprint-dialog.component.scss'],
})
export class ImprintDialogComponent implements OnInit {
  readonly #dialogRef = inject(MatDialogRef<ImprintDialogComponent>);

  ngOnInit(): void {
    this.#dialogRef.addPanelClass(['dialog-medium-size', 'dialog-nopadding']);
  }
}
