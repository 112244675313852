<form #loginForm="ngForm">
  <div class="login-panel box">
    @if (showHeader) {
      <div class="login-header">
        {{ (resetLoginView ? 'LOGIN.FORGOT_PWD' : 'LOGIN.LOGIN') | translate }}
      </div>
    }

    <div class="login-content dark">
      @if (isWhitelistActive) {
        <div class="alert alert-danger">
          <div class="row">
            <span class="ui icon-alert"></span>
            <p class="message-title">{{ 'LOGIN.PORTAL_MAINTENANCE' | translate }}</p>
          </div>
        </div>
      } @else if (error) {
        <div class="alert alert-danger">
          <div class="row">
            <span class="ui icon-alert"></span>
            <p class="message-title">{{ error | translate }}</p>
          </div>
        </div>
      }

      @if (passwordResetted) {
        <div class="alert alert-success">
          <div class="row">
            <span class="ui icon-success"></span>
            <p class="message-title">{{ 'USER.ONE_TIME_PW_SENT' | translate }}</p>
          </div>
        </div>
      }

      <mat-form-field class="no-hint-padding" hideRequiredMarker="true" appearance="outline">
        <mat-label>{{ 'USER.USERNAME' | translate }}</mat-label>
        <input
          matInput
          [ngModel]="loginInputModel.username"
          (ngModelChange)="loginInputModel.username = $event; restartClearInputsTimer()"
          id="appLoginUserNameInput"
          required
          name="username"
          maxlength="100"
          autocomplete="username"
          tabindex="1"
          [errorStateMatcher]="errorStateMatcher"
        />
      </mat-form-field>

      <mat-form-field class="no-hint-padding" hideRequiredMarker="true" appearance="outline">
        <mat-label>{{ 'USER.ORGA_ID' | translate }}</mat-label>
        <input
          matInput
          [ngModel]="loginInputModel.organisation"
          (ngModelChange)="loginInputModel.organisation = $event; restartClearInputsTimer()"
          id="appLoginOrganisationInput"
          required
          name="organisation"
          maxlength="100"
          autocomplete="organization"
          tabindex="1"
          [errorStateMatcher]="errorStateMatcher"
        />
      </mat-form-field>

      @if (!resetLoginView) {
        <mat-form-field class="no-hint-padding" hideRequiredMarker="true" appearance="outline">
          <mat-label>{{ 'USER.PASSWORD' | translate }}</mat-label>
          <input
            matInput
            [ngModel]="loginInputModel.password"
            (ngModelChange)="loginInputModel.password = $event; restartClearInputsTimer()"
            id="appLoginPasswordInput"
            type="password"
            required
            name="password"
            maxlength="100"
            autocomplete="current-password"
            tabindex="1"
            [errorStateMatcher]="errorStateMatcher"
          />
        </mat-form-field>
      }
    </div>

    <div class="login-actions">
      @if (!resetLoginView) {
        <a class="forgot-password clickable" id="appLoginSwitchToResetViewClickable" (click)="switchView()" tabindex="1">
          {{ 'LOGIN.FORGOT_PWD' | translate }}
        </a>
        <span class="flex-spacer"></span>
        <button
          mat-flat-button
          color="primary"
          [disabled]="!loginForm.valid || waitingForLoginResponse"
          id="appLoginPerformLoginButton"
          [appButtonLoading]="waitingForLoginResponse"
          (click)="performLogin()"
          tabindex="1"
          type="submit"
        >
          <span class="text-ellipsis">
            {{ 'LOGIN.' + (settings?.mode === 'LOGIN' ? 'LOGIN' : 'LOGIN_AND_ACCEPT') | translate }}
          </span>
        </button>
      } @else {
        <a class="text-ellipsis forgot-password clickable" id="appLoginSwitchViewToLoginClickable" (click)="switchView()" tabindex="1">
          {{ 'LOGIN.BACK_TO_LOGIN' | translate }}
        </a>
        <span class="flex-spacer"></span>
        <button
          mat-flat-button
          color="primary"
          [disabled]="!loginForm.valid || passwordResetted || isResettingPassword"
          id="appLoginResetPasswordButton"
          [appButtonLoading]="isResettingPassword"
          (click)="resetPassword()"
          tabindex="1"
        >
          <span class="text-ellipsis">
            {{ 'LOGIN.RESET_PWD' | translate }}
          </span>
        </button>
      }
    </div>
  </div>

  @if (settings?.mode === 'LOGIN_OR_REGISTER_AND_ACCEPT') {
    <div class="login-panel box">
      <div class="register-header">
        {{ 'LOGIN.NOT_REGISTERED_YET' | translate }}
      </div>
      <button
        class="margin-1-important"
        mat-flat-button
        color="primary"
        id="appLoginCreateAccountButton"
        (click)="settings?.register && settings?.register()"
        tabindex="1"
      >
        <span class="text-ellipsis">
          {{ 'LOGIN.CREATE_ACCOUNT' | translate }}
        </span>
      </button>
    </div>
  }
</form>
