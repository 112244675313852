@switch (status) {
  @case ('loading') {
    <div class="content box">
      <div class="spinner-container">
        <span class="spinner"></span>
      </div>
    </div>
  }
  @case ('error') {
    <div class="content box">
      <div class="loading-error">An Error occured while loading the page. Please try again.</div>
    </div>
  }
  @default {
    <div class="content">
      <div class="header-toolbar flexRow">
        <img [src]="logoUrl" class="logo" />

        <div class="flex-spacer"></div>

        @if (false) {
          <button mat-stroked-button class="text-ellipsis" (click)="openTermsOfServiceDialog()" tabindex="100">
            {{ 'TERMS_OF_SERVICE.TERMS_OF_SERVICE' | translate }}
          </button>
        }

        @if (false) {
          <button mat-stroked-button (click)="openPrivacyPolicyDialog()" tabindex="100">
            {{ 'PRIVACY_POLICY.PRIVACY_POLICY' | translate }}
          </button>
        }

        @if (!disableB2CAuth) {
          <button mat-stroked-button (click)="redirectToB2c()" tabindex="100">
            <span class="text-ellipsis">
              {{ 'LOGIN.FEDERATED_LOGIN' | translate }}
            </span>
          </button>
        }

        <button mat-stroked-button (click)="openHelpDialog()" tabindex="100">
          {{ 'GENERAL.HELP' | translate }}
        </button>

        <button mat-stroked-button (click)="openImprintDialog()" tabindex="100">
          {{ 'IMPRESSUM.IMPRESSUM' | translate }}
        </button>
      </div>

      <div class="page">
        <router-outlet></router-outlet>
      </div>

      <video muted="muted" loop="loop" class="video-body video-fullScreen" id="titlevideo" #video>
        @if (videoState !== undefined) {
          <source type="video/mp4" src="/assets/videos/video.mp4" />
        }
      </video>

      <button mat-icon-button class="play-button" (click)="toggleVideo(video)">
        <mat-icon>{{ video.paused ? 'play_arrow' : 'paused' }}</mat-icon>
      </button>
    </div>
  }
}
