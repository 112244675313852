import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { lastValueFrom } from 'rxjs';
import { AuthenticationResult } from 'src/app/models/authentication-result';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #authService = inject(AuthService);
  readonly #router = inject(Router);

  logoutFailed = false;
  logoutRetry = false;
  logoutToken = '';

  ngOnInit(): void {
    this.#activatedRoute.queryParams.pipe(untilComponentDestroyed(this)).subscribe(async (p) => {
      if (!this.logoutToken) {
        this.logoutToken = p.logoutId;
      }
      await this.doLogout(this.logoutToken);
    });
  }

  async doLogout(logoutId: string) {
    this.logoutRetry = true;

    const result = await lastValueFrom(this.#authService.logout(logoutId));
    if (result.result === AuthenticationResult.Redirect && result.redirectUrl) {
      setTimeout(() => this.#router.navigateByUrl('/login'), 2500);
    } else {
      this.logoutFailed = true;
    }
    this.logoutRetry = false;
  }
}
