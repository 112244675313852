import { HttpResponse } from '@angular/common/http';

export function downloadBlob(response: HttpResponse<any>): void {
  let filename = response.headers.get('X-Filename');

  if (!filename) {
    const contentDisposition = response.headers.get('Content-Disposition') ?? undefined;
    const names = contentDisposition?.match(/filename=([^;]+)/);

    if (names?.length) {
      filename = names[1];
    }
  }

  const fileData = new Blob([response.body], { type: response.headers.get('content-type') ?? undefined });
  downloadFile(fileData, filename ?? 'file');
}

export function openFile(response: HttpResponse<any>): void {
  const file = new Blob([response.body], { type: response.headers.get('content-type') ?? undefined });
  const objectUrl = window.URL.createObjectURL(file);

  try {
    window.open(objectUrl, '_blank');
  } finally {
    // manually free resources after opening
    window.URL.revokeObjectURL(objectUrl);
  }
}

export function downloadFile(file: File | Blob, fileName: string): void {
  const objectUrl = window.URL.createObjectURL(file); // For chrome,firefox,opera and safari

  try {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = objectUrl;
    a.download = decodeURIComponent(fileName);
    a.click();
    document.body.removeChild(a);
  } finally {
    // manually free resources after download
    window.URL.revokeObjectURL(objectUrl);
  }
}

export function dataURLtoFile(dataurl: string, filename: string): File {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
