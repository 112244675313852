import { Component, inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-of-service-dialog',
  templateUrl: './terms-of-service-dialog.component.html',
  styleUrls: ['./terms-of-service-dialog.component.scss'],
})
export class TermsOfServiceDialogComponent implements OnInit {
  readonly #dialogRef = inject(MatDialogRef<TermsOfServiceDialogComponent>);

  ngOnInit(): void {
    this.#dialogRef.addPanelClass(['dialog-medium-size', 'dialog-nopadding']);
  }
}
