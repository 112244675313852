import { Component, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { InstantErrorStateMatcher } from 'src/app/shared/instant-error-state-matcher';
import { AuthenticationResult } from '../../models/authentication-result';
import { ILoginInputModel, LoginInputModel } from '../../models/login-input-model';
import { PasswordResetInputModel } from '../../models/password-reset-input-model';
import { AuthService } from '../../services/auth.service';

export interface ILoginConfig {
  /**
   * callback for login
   * can return an error string that will be translated
   * can return null if the loading spinner should not be resetted
   */
  login: (m: LoginInputModel) => Promise<AuthenticationResult | null | undefined>;
  register?: () => void | Promise<void>;
  mode: 'LOGIN' | 'LOGIN_AND_ACCEPT' | 'LOGIN_OR_REGISTER_AND_ACCEPT';
}

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements OnDestroy {
  // clear password input after 60 seconds of inactivity
  readonly clearInputsTime = 60000;
  clearInputsTimer?: NodeJS.Timeout;

  isWhitelistActive = (window as any).isWhitelistActive as boolean;
  loginInputModel = new LoginInputModel();
  //#region in- and outputs
  @Input()
  get returnUrl(): string {
    return this.loginInputModel.returnUrl;
  }
  set returnUrl(v: string) {
    this.loginInputModel.returnUrl = v;
  }
  @Input()
  showHeader = true;

  @Input()
  settings: ILoginConfig | undefined;

  @Input()
  error: string | undefined;
  //#endregion

  @ViewChild(NgForm)
  loginForm: NgForm;

  resetLoginView = false;

  passwordResetted = false;

  waitingForLoginResponse = false;

  isResettingPassword = false;

  errorStateMatcher = new InstantErrorStateMatcher();

  #authService = inject(AuthService);

  static getLoginErrorText(result: AuthenticationResult | undefined): string {
    switch (result) {
      case AuthenticationResult.Failed:
        return 'BACKEND_ERROR.BAD_CREDENTIALS';
      case AuthenticationResult.NotYetAllowed:
        return 'USER.USER_LOGIN_NOT_YET_ALLOWED';
      case AuthenticationResult.NotAnymoreAllowed:
        return 'USER.USER_LOGIN_NOT_ANYMORE_ALLOWED';
      case AuthenticationResult.Error:
      case AuthenticationResult.OrganisationNotWhitelisted:
        return 'BACKEND_ERROR.TIMEOUT_TRY_AGAIN';
      case AuthenticationResult.InviteNotApplicable:
        return 'BACKEND_ERROR.INVITE_NOT_APPLICABLE';
      case AuthenticationResult.OrganisationBlockedOrInactive:
        return 'LOGIN.ORGANISATION_BLOCKED_OR_INACTIVE';
      default:
        return 'BACKEND_ERROR.UNSPECIFIC';
    }
  }

  setError(e: string | undefined): void {
    this.error = e;
  }

  async performLogin(model?: ILoginInputModel): Promise<void> {
    this.clearMessages();
    if (this.settings) {
      this.waitingForLoginResponse = true;
      try {
        const result = await this.settings.login(model ? { ...model, returnUrl: this.loginInputModel.returnUrl } : this.loginInputModel);

        if (result === null) {
          this.error = undefined;
          return;
        } else if (result) {
          this.error = LoginDialogComponent.getLoginErrorText(result);
        } else {
          this.error = undefined;
        }
      } catch {
        this.error = 'BACKEND_ERROR.UNSPECIFIC';
      }
      this.waitingForLoginResponse = false;
    }
  }

  async resetPassword(): Promise<void> {
    this.isResettingPassword = true;
    const model = new PasswordResetInputModel();
    model.organisation = this.loginInputModel.organisation;
    model.username = this.loginInputModel.username;
    try {
      await lastValueFrom(this.#authService.resetPassword(model));
      this.clearMessages();
      this.passwordResetted = true;
      this.isResettingPassword = false;
    } catch {
      this.error = 'BACKEND_ERROR.UNSPECIFIC';
      this.passwordResetted = false;
    }
  }

  switchView(): void {
    this.resetLoginView = !this.resetLoginView;
    this.clearMessages();
  }
  clearMessages(): void {
    this.error = undefined;
    this.passwordResetted = false;
  }

  restartClearInputsTimer(): void {
    this.clearTimer();
    this.clearInputsTimer = setTimeout(this.clearInputs.bind(this), this.clearInputsTime);
  }

  private clearInputs(): void {
    this.loginForm.resetForm();
    this.error = undefined;
  }
  private clearTimer(): void {
    if (this.clearInputsTimer) {
      clearTimeout(this.clearInputsTimer);
      this.clearInputsTimer = undefined;
    }
  }

  ngOnDestroy(): void {
    this.clearTimer();
  }
}
