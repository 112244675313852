import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InviteComponent } from './components/invite/invite.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { LoginComponent } from './components/login/login.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { LogoutComponent } from './components/logout/logout.component';

const routes: Routes = [
  {
    path: 'invite/:type/:relationUuid/:token/:email',
    component: InviteComponent,
  },
  {
    path: 'invite/:type/:relationUuid/:token',
    component: InviteComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'optindigitalinvoice',
    component: InvoiceComponent,
  },
  {
    path: 'optInDigitalInvoice',
    component: InvoiceComponent,
  },
  { path: '**', component: RedirectComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
