import { Component, inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-privacy-policy-dialog',
  templateUrl: './privacy-policy-dialog.component.html',
  styleUrls: ['./privacy-policy-dialog.component.scss'],
})
export class PrivacyPolicyDialogComponent implements OnInit {
  readonly #dialogRef = inject(MatDialogRef<PrivacyPolicyDialogComponent>);

  ngOnInit(): void {
    this.#dialogRef.addPanelClass(['dialog-medium-size', 'dialog-nopadding']);
  }
}
