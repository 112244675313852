import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IAuthenticationAction } from '../models/authentication-action';
import { AuthenticationResult } from '../models/authentication-result';
import { LoginInputModel } from '../models/login-input-model';
import { PasswordResetInputModel } from '../models/password-reset-input-model';
import { IRegistrationModel } from '../models/registration-model';
import { TokenLoginModel } from '../models/token-login-model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly #http = inject(HttpClient);

  login(loginInputModel: LoginInputModel): Observable<IAuthenticationAction> {
    return this.#http.post<IAuthenticationAction>('api/account/login', loginInputModel, { observe: 'response' }).pipe(
      map((res) => res.body as IAuthenticationAction),
      catchError((err) => of<IAuthenticationAction>({ result: AuthenticationResult.Error, statusCode: err.status })),
    );
  }

  loginAcceptInvite(login: LoginInputModel, relationUuid: string, token: string): Observable<IAuthenticationAction> {
    return this.#http
      .post<IAuthenticationAction>('api/account/login/' + relationUuid + '/' + encodeURIComponent(token), login, {
        observe: 'response',
      })
      .pipe(
        map((res) => res.body as IAuthenticationAction),
        catchError((err) => of<IAuthenticationAction>({ result: AuthenticationResult.Error, statusCode: err.status })),
      );
  }

  loginConnectInvite(login: LoginInputModel, relationUuid: string, token: string): Observable<IAuthenticationAction> {
    return this.#http
      .post<IAuthenticationAction>('api/account/connect/' + relationUuid + '/' + encodeURIComponent(token), login, {
        observe: 'response',
      })
      .pipe(
        map((res) => res.body as IAuthenticationAction),
        catchError((err) => of<IAuthenticationAction>({ result: AuthenticationResult.Error, statusCode: err.status })),
      );
  }

  registerAcceptInvite(registration: IRegistrationModel, relationUuid: string, token: string): Observable<IAuthenticationAction> {
    return this.#http
      .post<IAuthenticationAction>('api/account/register/' + relationUuid + '/' + encodeURIComponent(token), registration, {
        observe: 'response',
      })
      .pipe(
        map((res) => res.body as IAuthenticationAction),
        catchError((err) => of<IAuthenticationAction>({ result: AuthenticationResult.Error, statusCode: err.status })),
      );
  }

  tryLoginWithRegistrationToken(tokenLoginModel: TokenLoginModel): Observable<IAuthenticationAction> {
    return this.#http
      .post<IAuthenticationAction>('api/account/tryLoginWithRegistrationToken', tokenLoginModel, { observe: 'response' })
      .pipe(
        map((res) => res.body as IAuthenticationAction),
        catchError((err) => of<IAuthenticationAction>({ result: AuthenticationResult.Error, statusCode: err.status })),
      );
  }

  logout(logoutId: string): Observable<IAuthenticationAction> {
    return this.#http
      .post<IAuthenticationAction>('api/account/logout', null, {
        params: {
          logoutId,
        },
      })
      .pipe(
        map((r) => r),
        catchError(() =>
          of({
            result: AuthenticationResult.Error,
          } as IAuthenticationAction),
        ),
      );
  }

  resetPassword(model: PasswordResetInputModel): Observable<void> {
    return this.#http.post<void>('api/account/ResetPassword', model);
  }

  organisationNameAllowed(organisation: string): Observable<boolean> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.#http
      .post<boolean>('api/account/preflight/organisation', JSON.stringify(organisation), { observe: 'response', headers })
      .pipe(
        map((response) => response.body),
        catchError(() => of(false)),
      );
  }

  vatPreflightCheck(vat: string): Observable<boolean | undefined> {
    return this.#http
      .post<boolean>('api/account/preflight/vat', JSON.stringify(vat), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      })
      .pipe(catchError(() => of(undefined)));
  }
}
