export enum TimeZoneUnit {
  /** UTC Time. +/-0, no summer time. */
  UTC = 0,

  /** HST Time -10h -0min, no summer time. */
  HST = 1,

  /** AKST Time. -9h -0min. */
  AKST = 2,

  /** PST Time. -8h -0min. */
  PST = 3,

  /** MST Time. -7h -0min. */
  MST = 4,

  /** CST Time. -6h -0min. */
  CST = 5,

  /** EST Time. -5h -0min. */
  EST = 6,

  /** AST Time. -4h -0min. */
  AST = 7,

  /** NST Time. -3h -30min. */
  NST = 8,

  /** BRT Time. -3h -0min, no summer time. */
  BRT = 9,

  /** GST Time. -2h -0min, no summer time. */
  GST = 10,

  /** EGT Time. -1h -0min, no summer time. */
  EGT = 11,

  /** GMT Time. +/-0. */
  GMT = 12,

  /** WET Time. +/-0. */
  WET = 13,

  /** CET Time. 1h 0min. */
  CET = 14,

  /** EET Time. 2h 0min. */
  EET = 15,

  /** MSK Time. 3h 0min, no summer time. */
  MSK = 16,

  /** IRST Time. 3h 30min. */
  IRST = 17,

  /** AMT Time. 4h 0min, no summer time. */
  AMT = 18,

  /** AFST Time. 4h 30min, no summer time. */
  AFST = 19,

  /** PKT Time. 5h 0min, no summer time. */
  PKT = 20,

  /** IST Time. 5h 30min, no summer time. */
  IST = 21,

  /** NOVT Time. 6h 0min, no summer time. */
  NOVT = 22,

  /** MMT Time. 6h 30min, no summer time. */
  MMT = 23,

  /** ICT Time. 7h 0min, no summer time. */
  ICT = 24,

  /** HKT Time. 8h 0min, no summer time. */
  HKT = 25,

  /** AWST Time. 8h 0min, no summer time. */
  AWST = 26,

  /** KST Time. 8h 30min, no summer time. */
  KST = 27,

  /** JST Time. 9h 0min, no summer time. */
  JST = 28,

  /** ACST Time. 9h 30min */
  ACST = 29,

  /** AEST Time. 10h 0min */
  AEST = 30,

  /** NFT Time. 11h 0min, no summer time. */
  NFT = 31,

  /** NZST Time. 12h 0min */
  NZST = 32,
}

export const TIMEZONE_OFFSET: Record<number, TimeZoneUnit> = {
  [-10]: TimeZoneUnit.HST,
  [-9]: TimeZoneUnit.AKST,
  [-8]: TimeZoneUnit.PST,
  [-7]: TimeZoneUnit.MST,
  [-6]: TimeZoneUnit.CST,
  [-5]: TimeZoneUnit.EST,
  [-4]: TimeZoneUnit.AST,
  [-3.5]: TimeZoneUnit.NST,
  [-3]: TimeZoneUnit.BRT,
  [-2]: TimeZoneUnit.GST,
  [-1]: TimeZoneUnit.EGT,
  [0]: TimeZoneUnit.UTC,
  // TimeZoneUnit.GMT and TimeZoneUnit.WET would also be 0 but we use utc
  [1]: TimeZoneUnit.CET,
  [2]: TimeZoneUnit.EET,
  [3]: TimeZoneUnit.MSK,
  [3.5]: TimeZoneUnit.IRST,
  [4]: TimeZoneUnit.AMT,
  [4.5]: TimeZoneUnit.AFST,
  [5]: TimeZoneUnit.PKT,
  [5.5]: TimeZoneUnit.IST,
  [6]: TimeZoneUnit.NOVT,
  [6.5]: TimeZoneUnit.MMT,
  [7]: TimeZoneUnit.ICT,
  [8]: TimeZoneUnit.HKT,
  // TimeZoneUnit.AWST would also be 8 but we use hkt
  [9]: TimeZoneUnit.KST,
  // TimeZoneUnit.JST would also be 9 but we use kst:
  [9.5]: TimeZoneUnit.ACST,
  [10]: TimeZoneUnit.AEST,
  [11]: TimeZoneUnit.NFT,
  [12]: TimeZoneUnit.NZST,
};

export const TIME_ZONE_NAME_CONFIG: Record<TimeZoneUnit, string> = {
  [TimeZoneUnit.HST]: 'Pacific/Honolulu',
  [TimeZoneUnit.AKST]: 'America/Yakutat',
  [TimeZoneUnit.PST]: 'America/Vancouver',
  [TimeZoneUnit.MST]: 'America/Denver',
  [TimeZoneUnit.CST]: 'America/Chicago',
  [TimeZoneUnit.EST]: 'America/New_York',
  [TimeZoneUnit.AST]: 'Atlantic/Bermuda',
  [TimeZoneUnit.NST]: 'Canada/Newfoundland',
  [TimeZoneUnit.BRT]: 'America/Argentina/Buenos_Aires',
  [TimeZoneUnit.GST]: 'Atlantic/South_Georgia',
  [TimeZoneUnit.EGT]: 'Atlantic/Azores',
  [TimeZoneUnit.UTC]: 'UTC',
  [TimeZoneUnit.GMT]: 'Europe/London',
  [TimeZoneUnit.WET]: 'Atlantic/Canary',
  [TimeZoneUnit.CET]: 'Europe/Paris',
  [TimeZoneUnit.EET]: 'Europe/Sofia',
  [TimeZoneUnit.MSK]: 'Europe/Moscow',
  [TimeZoneUnit.IRST]: 'Asia/Tehran',
  [TimeZoneUnit.AMT]: 'Asia/Baku',
  [TimeZoneUnit.AFST]: 'Asia/Kabul',
  [TimeZoneUnit.PKT]: 'Asia/Karachi',
  [TimeZoneUnit.IST]: 'Asia/Calcutta',
  [TimeZoneUnit.NOVT]: 'Asia/Bishkek',
  [TimeZoneUnit.MMT]: 'Asia/Yangon',
  [TimeZoneUnit.ICT]: 'Indian/Christmas',
  [TimeZoneUnit.HKT]: 'Asia/Hong_Kong',
  [TimeZoneUnit.AWST]: 'Australia/West',
  [TimeZoneUnit.KST]: 'Asia/Pyongyang',
  [TimeZoneUnit.JST]: 'Asia/Pyongyang',
  [TimeZoneUnit.ACST]: 'Australia/Adelaide',
  [TimeZoneUnit.AEST]: 'Australia/Tasmania',
  [TimeZoneUnit.NFT]: 'Pacific/Kosrae',
  [TimeZoneUnit.NZST]: 'Pacific/Auckland',
};
