import { DateFormat, DistanceUnit, NumberFormat, TimeZoneUnit } from '../shared/constants';

export interface IRegistrationModel {
  user: IUser;
  organisation: IOrganisation;
  acceptedDpa: {
    uuid: string;
    language: string;
  };
}

export interface IUser {
  mail: string;
  firstName: string;
  lastName: string;
  organisation: string;
  login: string;
  password: string;
  localeConfig: ILocaleConfig;
}

export interface ILocaleConfig {
  /// <summary>
  /// the preferred timezone
  /// </summary>
  /// TODO this unit should be a city name in the future (meaning an IANA/ TZ timezone identifier combining Area/Location.)
  timeZoneUnit: TimeZoneUnit;

  /// <summary>
  /// the preferred language
  /// </summary>
  language: string;

  /// <summary>
  /// the preferred dateformat
  /// </summary>
  dateFormat: DateFormat;

  /// <summary>
  /// the char used as the decimal point in non integer units
  /// </summary>
  numberFormat: NumberFormat;

  /// <summary>
  /// the preferred units of length based units
  /// </summary>
  distanceSystem: DistanceUnit;

  /// <summary>
  /// the preferred units for mass based values
  /// </summary>
  weightSystem: WeightSystem;

  /// <summary>
  /// the preferred unit for temperature based values
  /// the default value 0 is Kelvin, so we have to specify Celsius
  /// </summary>
  temperatureSystem: TemperatureSystem;

  /// <summary>
  /// the preferred unit for volume based values
  /// </summary>
  volumeSystem: VolumeSystem;

  /// <summary>
  /// the preferred unit for pressure based values
  /// </summary>
  pressureSystem: PressureSystem;
}

export interface IOrganisation {
  fullName: string;
  vatNr: string;
  country: string;
  city: string;
  street: string;
  houseNr: string;
  zipCode: string;
  email: string;
  tel: string;
  fax?: string;
}

/** enum to define the different types of weight unit systems */
export enum WeightSystem {
  /** metric based weight units */
  METRIC = 0,

  /** imperial based weight units */
  IMPERIAL = 1,
}
export enum VolumeSystem {
  LITER = 0,
  GALLON = 1,
}
/** represents the main temperature units */
export enum TemperatureSystem {
  /** science standard */
  KELVIN = 0,

  /** european standard */
  CELSIUS = 1,

  /** american standard */
  FAHRENHEIT = 2,
}
export enum PressureSystem {
  /** metric based pressure units */
  METRIC = 0,

  /** imperial based pressure units */
  IMPERIAL = 1,
}
