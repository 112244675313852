import { AfterViewInit, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { lastValueFrom } from 'rxjs';
import { shouldDisplayUtilityLogo } from '../app-component/utilities/provider-brand-utilities';
import { ImprintDialogComponent } from '../components/dialogs/imprint-dialog/imprint-dialog.component';
import { PrivacyPolicyDialogComponent } from '../components/dialogs/privacy-policy-dialog/privacy-policy-dialog.component';
import { TermsOfServiceDialogComponent } from '../components/dialogs/terms-of-service-dialog/terms-of-service-dialog.component';
import { HelpDialogComponent } from '../components/help-dialog/help-dialog.component';
import { LogoService } from '../services/logo.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends OnDestroyMixin implements OnInit, AfterViewInit, OnDestroy {
  readonly #logoService = inject(LogoService);
  readonly #translateService = inject(TranslateService);
  readonly #dialog = inject(MatDialog);

  status: 'loading' | 'success' | 'error' = 'loading';

  disableB2CAuth = window['disableB2CAuth'];
  logoUrl?: string;

  videoState?: boolean;
  toggleVideo(video: HTMLVideoElement): void {
    if (this.videoState === undefined) {
      this.videoState = false;

      setTimeout(() => this.toggleVideo(video), 0);
      return;
    }

    this.videoState = !this.videoState;
    if (this.videoState) {
      video.play();
    } else {
      video.pause();
    }
  }

  openTermsOfServiceDialog(): void {
    this.#dialog.open(TermsOfServiceDialogComponent, {
      restoreFocus: false,
    });
  }

  openPrivacyPolicyDialog(): void {
    this.#dialog.open(PrivacyPolicyDialogComponent, {
      restoreFocus: false,
    });
  }

  openHelpDialog(): void {
    this.#dialog.open(HelpDialogComponent, {
      width: '600px',
      restoreFocus: false,
    });
  }

  openImprintDialog(): void {
    this.#dialog.open(ImprintDialogComponent, {
      restoreFocus: false,
    });
  }

  redirectToB2c(): void {
    window.location.replace(window['portalUrl'] + '?b2c');
  }

  #setBrand() {
    this.#logoService.logoUrl$.pipe(untilComponentDestroyed(this)).subscribe((url) => {
      this.logoUrl = url;
    });

    const associatedBrand = shouldDisplayUtilityLogo() ? 'utility' : 'scb';
    this.#logoService.setLogoUrl(associatedBrand);
  }

  async #initLanguage(): Promise<void> {
    // init translateService
    this.#translateService.addLangs([
      'bs',
      'cs',
      'da',
      'de',
      'el',
      'en',
      'es',
      'fi',
      'fr',
      'hr',
      'hu',
      'it',
      'lt',
      'nl',
      'no',
      'pl',
      'pt',
      'ro',
      'ru',
      'sr',
      'sv',
      'tr',
    ]);

    this.#translateService.setDefaultLang('en');

    const language = this.#translateService.getBrowserLang();
    if (this.#translateService.langs.includes(language)) {
      await lastValueFrom(this.#translateService.use(language));
    }
  }

  async ngOnInit(): Promise<void> {
    this.#setBrand();

    await this.#initLanguage()
      .then(() => (this.status = 'success'))
      .catch(() => (this.status = 'error'));
  }

  ngAfterViewInit(): void {
    const backgroundVideo = window.document.getElementById('titlevideo') as HTMLVideoElement;
    if (backgroundVideo) {
      backgroundVideo.muted = true;
    }
  }
}
