export interface ILoginInputModel {
  username: string;
  password: string;
  returnUrl: string;
  organisation: string;
}

export class LoginInputModel implements ILoginInputModel {
  username: string;
  password: string;
  returnUrl: string;
  organisation: string;

  constructor() {
    this.username = '';
    this.password = '';
    this.returnUrl = '';
    this.organisation = '';
  }
}
