import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

/// <summary>
/// https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s21.html
/// </summary>
export const VAT_PATTERN =
  /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;

export const EMAIL_PATTERN =
  /^(?:(([^<>\(\)\[\]\.,;:\s@"]+(\.[^<>\(\)\[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/;
export const USER_ACCOUNT_NAME_PATTERN = /^[-a-zA-Z0-9_]+$/;

export function isValidPasswordInput(username: string, password: string): boolean {
  if (password.length < 8 || password.length > 100) {
    return false;
  }

  if (username?.length && password.includes(username)) {
    return false;
  }

  const passwordRegex = [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9_]/];
  let validRegex = 0;
  for (const r of passwordRegex) {
    if (password.match(r) != null) {
      validRegex++;
    }
  }

  return validRegex >= 3;
}

export const LANGUAGES = [
  'EN',
  'DE',
  'RU',
  'SV',
  'FR',
  'HU',
  'ES',
  'NL',
  'IT',
  'HR',
  'BS',
  'PL',
  'FI',
  'NO',
  'DA',
  'TR',
  'CS',
  'EL',
  'LT',
  'PT',
  'RO',
  'SR',
];

export type DateFormat = 'DE' | 'US';
export const DATE_FORMATS: DateFormat[] = ['DE', 'US'];

export enum TimeZoneUnit {
  /** UTC Time. +/-0, no summer time. */
  UTC = 0,

  /** HST Time -10h -0min, no summer time. */
  HST = 1,

  /** AKST Time. -9h -0min. */
  AKST = 2,

  /** PST Time. -8h -0min. */
  PST = 3,

  /** MST Time. -7h -0min. */
  MST = 4,

  /** CST Time. -6h -0min. */
  CST = 5,

  /** EST Time. -5h -0min. */
  EST = 6,

  /** AST Time. -4h -0min. */
  AST = 7,

  /** NST Time. -3h -30min. */
  NST = 8,

  /** BRT Time. -3h -0min, no summer time. */
  BRT = 9,

  /** GST Time. -2h -0min, no summer time. */
  GST = 10,

  /** EGT Time. -1h -0min, no summer time. */
  EGT = 11,

  /** GMT Time. +/-0. */
  GMT = 12,

  /** WET Time. +/-0. */
  WET = 13,

  /** CET Time. 1h 0min. */
  CET = 14,

  /** EET Time. 2h 0min. */
  EET = 15,

  /** MSK Time. 3h 0min, no summer time. */
  MSK = 16,

  /** IRST Time. 3h 30min. */
  IRST = 17,

  /** AMT Time. 4h 0min, no summer time. */
  AMT = 18,

  /** AFST Time. 4h 30min, no summer time. */
  AFST = 19,

  /** PKT Time. 5h 0min, no summer time. */
  PKT = 20,

  /** IST Time. 5h 30min, no summer time. */
  IST = 21,

  /** NOVT Time. 6h 0min, no summer time. */
  NOVT = 22,

  /** MMT Time. 6h 30min, no summer time. */
  MMT = 23,

  /** ICT Time. 7h 0min, no summer time. */
  ICT = 24,

  /** HKT Time. 8h 0min, no summer time. */
  HKT = 25,

  /** AWST Time. 8h 0min, no summer time. */
  AWST = 26,

  /** KST Time. 8h 30min, no summer time. */
  KST = 27,

  /** JST Time. 9h 0min, no summer time. */
  JST = 28,

  /** ACST Time. 9h 30min */
  ACST = 29,

  /** AEST Time. 10h 0min */
  AEST = 30,

  /** NFT Time. 11h 0min, no summer time. */
  NFT = 31,

  /** NZST Time. 12h 0min */
  NZST = 32,
}

export enum DistanceUnit {
  KILOMETER = 0,
  MILE = 1,
}

export enum NumberFormat {
  DOT = 0,
  COMMA = 1,
}

export function addLine(inputString: string, spacesafterBreak: number = 0, breakAfter: number = 30): string {
  const splitted = inputString.split(' ');
  const splittedLength = splitted.length;
  let charCount = 0;
  inputString = '';

  for (let index = 0; index < splittedLength; index++) {
    const word = splitted[index];

    inputString += word + ' ';
    charCount += word.length;

    if (charCount > breakAfter && index !== splittedLength - 1) {
      charCount = 0;

      inputString += '\n';

      for (let innerIndex = 0; innerIndex < spacesafterBreak; innerIndex++) {
        inputString += ' ';
      }
    }
  }

  return inputString;
}

export async function getPasswordToolTip(translateService: TranslateService): Promise<string> {
  let rulesIntro: string = await lastValueFrom(translateService.get('USER.PWD_RULES_INTRO'));
  let rule1Text: string = await lastValueFrom(translateService.get('USER.PWD_RULES_RULE1_TEXT'));
  let rule2text: string = await lastValueFrom(translateService.get('USER.PWD_RULES_RULE2_TEXT1'));
  let rule2Group1: string = await lastValueFrom(translateService.get('USER.PWD_RULES_RULE2_GROUP1'));
  let rule2Group2: string = await lastValueFrom(translateService.get('USER.PWD_RULES_RULE2_GROUP2'));
  let rule2Group3: string = await lastValueFrom(translateService.get('USER.PWD_RULES_RULE2_GROUP3'));
  let rule2Group4: string = await lastValueFrom(translateService.get('USER.PWD_RULES_RULE2_GROUP4'));
  let rule3Text: string = await lastValueFrom(translateService.get('USER.PWD_RULES_RULE3_TEXT'));

  rulesIntro = addLine(rulesIntro);
  rule1Text = addLine(rule1Text, 8);
  rule2text = addLine(rule2text, 8);
  rule2Group1 = addLine(rule2Group1, 10);
  rule2Group2 = addLine(rule2Group2, 10);
  rule2Group3 = addLine(rule2Group3, 10);
  rule2Group4 = addLine(rule2Group4, 10);
  rule3Text = addLine(rule3Text, 8);

  const ret = `
  ${rulesIntro}

    1. ${rule1Text}

    2. ${rule2text}

        • ${rule2Group1}
        • ${rule2Group2}
        • ${rule2Group3}
        • ${rule2Group4}

    3. ${rule3Text}
  `;

  return ret;
}

export const TIME_ZONE_NAME_CONFIG: Record<TimeZoneUnit, IanaTimeZoneName> = {
  [TimeZoneUnit.HST]: 'Pacific/Honolulu',
  [TimeZoneUnit.AKST]: 'America/Yakutat',
  [TimeZoneUnit.PST]: 'America/Vancouver',
  [TimeZoneUnit.MST]: 'America/Denver',
  [TimeZoneUnit.CST]: 'America/Chicago',
  [TimeZoneUnit.EST]: 'America/New_York',
  [TimeZoneUnit.AST]: 'Atlantic/Bermuda',
  [TimeZoneUnit.NST]: 'Canada/Newfoundland',
  [TimeZoneUnit.BRT]: 'America/Argentina/Buenos_Aires',
  [TimeZoneUnit.GST]: 'Atlantic/South_Georgia',
  [TimeZoneUnit.EGT]: 'Atlantic/Azores',
  [TimeZoneUnit.UTC]: 'UTC',
  [TimeZoneUnit.GMT]: 'Europe/London',
  [TimeZoneUnit.WET]: 'Atlantic/Canary',
  [TimeZoneUnit.CET]: 'Europe/Paris',
  [TimeZoneUnit.EET]: 'Europe/Sofia',
  [TimeZoneUnit.MSK]: 'Europe/Moscow',
  [TimeZoneUnit.IRST]: 'Asia/Tehran',
  [TimeZoneUnit.AMT]: 'Asia/Baku',
  [TimeZoneUnit.AFST]: 'Asia/Kabul',
  [TimeZoneUnit.PKT]: 'Asia/Karachi',
  [TimeZoneUnit.IST]: 'Asia/Calcutta',
  [TimeZoneUnit.NOVT]: 'Asia/Bishkek',
  [TimeZoneUnit.MMT]: 'Asia/Yangon',
  [TimeZoneUnit.ICT]: 'Indian/Christmas',
  [TimeZoneUnit.HKT]: 'Asia/Hong_Kong',
  [TimeZoneUnit.AWST]: 'Australia/West',
  [TimeZoneUnit.KST]: 'Asia/Pyongyang',
  [TimeZoneUnit.JST]: 'Asia/Pyongyang',
  [TimeZoneUnit.ACST]: 'Australia/Adelaide',
  [TimeZoneUnit.AEST]: 'Australia/Tasmania',
  [TimeZoneUnit.NFT]: 'Pacific/Kosrae',
  [TimeZoneUnit.NZST]: 'Pacific/Auckland',
};

export type IanaTimeZoneName =
  | 'Pacific/Honolulu'
  | 'America/Yakutat'
  | 'America/Vancouver'
  | 'America/Denver'
  | 'America/Chicago'
  | 'America/New_York'
  | 'Atlantic/Bermuda'
  | 'Canada/Newfoundland'
  | 'America/Argentina/Buenos_Aires'
  | 'Atlantic/South_Georgia'
  | 'Atlantic/Azores'
  | 'UTC'
  | 'Europe/London'
  | 'Atlantic/Canary'
  | 'Europe/Paris'
  | 'Europe/Sofia'
  | 'Europe/Moscow'
  | 'Asia/Tehran'
  | 'Asia/Baku'
  | 'Asia/Kabul'
  | 'Asia/Karachi'
  | 'Asia/Calcutta'
  | 'Asia/Bishkek'
  | 'Asia/Yangon'
  | 'Indian/Christmas'
  | 'Asia/Hong_Kong'
  | 'Australia/West'
  | 'Asia/Pyongyang'
  | 'Asia/Pyongyang'
  | 'Australia/Adelaide'
  | 'Australia/Tasmania'
  | 'Pacific/Kosrae'
  | 'Pacific/Auckland';

export function getTimeZones(): {
  key: TimeZoneUnit;
  name: IanaTimeZoneName;
  offset: number;
  formattedOffset: string;
}[] {
  const now = new Date();
  now.setMilliseconds(0);

  return Object.keys(TimeZoneUnit)
    .map((item) => Number(item))
    .filter((item) => !isNaN(item))
    .map((timeZoneUnit: TimeZoneUnit) => {
      const intlZone = TIME_ZONE_NAME_CONFIG[timeZoneUnit];

      // calculate offset: for current date
      const utcDate = new Date(now.toLocaleString('en-US', { timeZone: 'UTC' }));
      const tzDate = new Date(now.toLocaleString('en-US', { timeZone: intlZone }));

      const offset = (tzDate.getTime() - utcDate.getTime()) / 1000 / 60 / 60;
      const offsetPrefix = offset > 0 ? '+' : offset < 0 ? '-' : '+/-';

      const absOffset = Math.abs(offset);
      const offsetHours = Math.floor(absOffset).toString();

      const offsetMinutes = (absOffset % 1) * 60;
      const offsetFormattedMinutes = offsetMinutes < 10 ? `0${offsetMinutes}` : `${offsetMinutes}`;

      const formattedOffset = offsetPrefix + offsetHours + ':' + offsetFormattedMinutes;

      return { key: timeZoneUnit, name: intlZone, offset, formattedOffset };
    })
    .sort((a, b) => a.offset - b.offset);
}
