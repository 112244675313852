import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  #logoUrl$ = new ReplaySubject<string>(1);

  get logoUrl$(): Observable<string> {
    return this.#logoUrl$;
  }

  setLogoUrl(associatedBrand: string): void {
    this.#logoUrl$.next(`/assets/images/logo_${associatedBrand.toLowerCase()}.svg`);
  }
}
