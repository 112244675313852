import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { lastValueFrom } from 'rxjs';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  /**
   * @returns dismissedByAction when the snackbar is closed
   */
  async openSnackBar(message: string = '', success: boolean = true, messageParameters: object = {}): Promise<boolean> {
    const openSnackbar = this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 7000,
      panelClass: [success ? 'snackbar-success' : 'snackbar-error', 'custom-snackbar'],
      data: { message, messageParameters },
    });

    const closed = await lastValueFrom(openSnackbar.afterDismissed());
    return closed.dismissedByAction;
  }
}
